import { useQuery } from '@tanstack/react-query';
import { WorkspaceQueryKeys } from './requests/workspace-query-keys';
import { Workspace } from './types/workspace';
import { BackendResponse } from '../http/backend-response/backend-response';
import { apiClient } from '../http/query-client';

export interface FetchUserWorkspacesResponse extends BackendResponse {
  workspaces: Workspace[];
  user_has_pin: boolean;
}

export const PersonalWorkspace: Workspace = {
  name: 'Default',
  default: true,
  id: 0,
  members_count: 1,
  has_pin: false
};

export const getPersonalWorkspace = (has_pin: boolean): Workspace => {
  return {
    ...PersonalWorkspace,
    has_pin
  };
};

function fetchUserWorkspaces(): Promise<FetchUserWorkspacesResponse> {
  return apiClient.get(`me/workspaces`).then(response => response.data);
}

function addPersonalWorkspaceToResponse(response: FetchUserWorkspacesResponse) {
  return [getPersonalWorkspace(response.user_has_pin), ...response.workspaces];
}

export function useUserWorkspaces() {
  return useQuery({
    queryKey: WorkspaceQueryKeys.fetchUserWorkspaces,
    queryFn: () => fetchUserWorkspaces(),
    placeholderData: { workspaces: [], user_has_pin: false },
    select: addPersonalWorkspaceToResponse,
  });
}
