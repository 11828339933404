let activeWorkspacePin = "";

// for access outside react
export function getActiveWorkspacePin() {
  return activeWorkspacePin;
}

export function setActiveWorkspacePin(pin: string) {
  activeWorkspacePin = pin;
}
